var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-table",
    { staticClass: "custom-paginated-table" },
    [
      _c(
        "md-table-row",
        [
          _c("md-table-head", [_vm._v("Event Trigger")]),
          _c("md-table-head", [_vm._v("Settings")]),
          _c("md-table-head", { staticClass: "actions" }, [_vm._v("Actions")]),
        ],
        1
      ),
      _vm._l(_vm.customActions, function (item) {
        return _c(
          "md-table-row",
          { key: item.teamCustomActionId },
          [
            _c("md-table-cell", [_vm._v(_vm._s(item.eventTriggerName))]),
            _c("md-table-cell", [
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "setting-menu",
                      attrs: {
                        to: {
                          name: "Job Offer Settings",
                        },
                      },
                    },
                    [_vm._v("\n                    Settings\n                ")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "md-table-cell",
              { staticClass: "action-buttons", attrs: { align: "end" } },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-warning md-just-icon md-round",
                    attrs: {
                      title: "Update custom action",
                      "data-test-id": "btn-update-" + item.teamCustomActionId,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("updateClicked", item)
                      },
                    },
                  },
                  [_c("md-icon", [_vm._v("edit")])],
                  1
                ),
                _c(
                  "md-button",
                  {
                    staticClass: "md-danger md-just-icon md-round",
                    attrs: {
                      title: "Delete custom action",
                      "data-test-id": "btn-delete-" + item.teamCustomActionId,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("deleteClicked", item)
                      },
                    },
                  },
                  [_c("md-icon", [_vm._v("delete")])],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }